export function getBase64(fileObj) {
  return new Promise(function(resolve, reject) {
    const reader = new FileReader();

    reader.onload = function() {
      resolve(reader.result);
    };

    reader.onerror = function(error) {
      reject(error);
    };

    reader.readAsDataURL(fileObj);
  });
}

export function getArrayBuffer(fileObj) {
  return new Promise(function(resolve, reject) {
    const reader = new FileReader();

    reader.onload = function() {
      resolve(reader.result);
    };

    reader.onerror = function(error) {
      reject(error);
    };

    reader.readAsArrayBuffer(fileObj);
  });
}

export function getImageFromUrl(url) {
  return new Promise(function(resolve, reject) {
    const httpRequest = new XMLHttpRequest();
    httpRequest.onload = () => {
      const fr = new FileReader();
      fr.onloadend = () => {
        resolve(fr.result);
      };
      fr.onerror = (error) => {
        reject(error);
      };
      fr.readAsDataURL(httpRequest.response);
    };

    httpRequest.open('GET', url);
    httpRequest.responseType = 'blob';
    httpRequest.send();
  });
}
